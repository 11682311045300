import React, { useState } from "react";
import view from "./dropdown.module.scss";

const DropdownOption = ({ type, values, addFilter, label, sorter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const renderOptions = () => {
    const keys = Object.keys(values);
    const sortedKeys = sorter ? keys.sort(sorter) : keys;

    if (!isOpen) {
      return null; // Return nothing if the dropdown is closed
    }

    return sortedKeys.map((key, idx) => (
      <li
        key={idx}
        className={view["sub-dropdown__item"]}
        onClick={() => {
          onSelected(key);
        }}
      >
        {`${key} (${values[key]})`}
      </li>
    ));
  };

  const onSelected = (value) => {
    if (value !== selectedOption) {
      // Handle your logic here based on the selected value
      addFilter({ value, type });
      setSelectedOption(value);
    }

    toggleDropdown(); // Close the dropdown after an option is selected
    document.activeElement.blur();
  };

  return (
    <ul className={view["dropdowns"]} style={{ padding: 0 }}>
      <li className={view["dropdown"]} style={{ width: "100%" }}>
        <a tabIndex="0" className={view["dropdown__inner"]} onClick={toggleDropdown}>
          <p className={view["dropdown__name"]}>{label && label}</p>
          <p className={view["dropdown__value"]}>{selectedOption}</p>
        </a>
        {isOpen && (
          <ul tabIndex="0" className={view["sub-dropdown"]}>
            {renderOptions()}
          </ul>
        )}
      </li>
    </ul>
  );
};

export default DropdownOption;
